import React from 'react'
import { get } from 'lodash'
import { Col, Row } from 'react-bootstrap'
import TitleDescription from 'components/molecules/TitleDescription'

function TabelaOPME({ data, user }) {
  const title = get(data, 'title', '')
  const description = get(data, 'description.value')
  const userProfile = user?.menu === 'secretaria' || user?.menu?.includes('secretaria') ? 'cooperado' : user?.menu ?? ''

  return (
    <div className="my-5">
      <Row>
        <Col>
          <TitleDescription className="mb-2" description={description} title={title} />
        </Col>
      </Row>
      <Row>
        <a
          className="btn btn-outline-primary btn-lg ml-3"
          href={`https://sgmed.unimedcuritiba.com.br?token=7oaMyKpqaEUE8FV1MMxCPEa5TRXHb_9UGm6MqnwjksnQJeJqJHcbNVvUG9gCmM-w&usuarioCodigo=${user?.contract}&usuarioPerfil=${userProfile}`}
        >
          Acessar
        </a>
      </Row>
    </div>
  )
}

export default TabelaOPME
